import { Button, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react"
import Layout from "../../components/shared-layout";
import Breadcrumb from "../../components/shared-breadcrumb";
import useStores from "../../hooks/use-stores"
import VmTable from "../../components/shared-table";
import VmModal from "../../components/shared-modal";
import { Link, navigate } from "gatsby";
import { isBrowser, PAGE_SIZE, ProdEnvCheck } from "../../constants/options";
import { t } from "i18next";
import { DateUtils } from "../../utilities/date";
import ContentLayout from "../../components/shared-content-layout";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { ITEM_PERFECT_INLINED, TD_FIRST, TD_LAST, TD_NORMAL } from "../../constants/style";
import { Box } from "@mui/system";

const EnrollFormIndexPage = observer(() => {
  const { enrollmentStore, rootStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [formId, setFormId] = useState<number>(0);
  const userInfo = isBrowser && localStorage.USER_INFO ? JSON.parse(localStorage.USER_INFO) : {};

  useEffect(() => {
    enrollmentStore.getFormList();
  }, []);

  const createNewEnrolForm = () => {
    enrollmentStore.createEnrollForm()
      .then((form: any) => {
        setShowModal(false);
        navigate("/enroll-form/registration-form", {
          state: {
            formId: form,
            previewMode: false,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            middleName: userInfo.middleName,
            email: userInfo.email,
            birthDate: userInfo.birthDate && userInfo.birthDate.split('T')[0],
            traineeId: userInfo.id,
            usi: userInfo.usi,
          }
        })
      })
  }

  const handleDeleteModal = (id: number) => {
    setFormId(id);
    setShowDeleteModal(true);
  }

  return (
    <ContentLayout
      pageName={'Enroll Form'}
      pageHeading={'My Enroll Forms'}
      breadCrumb={[]}
      buttonLabel={'Create New Enrolment Form'}
      onClickAction={() => setShowModal(true)}
    >
      <VmTable
        loading={enrollmentStore.loading}
        page={0}
        thead={["ID", "Form Status", t('ACTION')]}
        tbody={enrollmentStore.formList.length > 0 ?
          enrollmentStore.formList.map((form: any, index: number) => (
            <>
              <Box marginY={1} />
              <tr key={`form_${index}`}>
                <td className={TD_FIRST}>{index + 1}</td>
                <td className={TD_NORMAL}>
                  <Box sx={ITEM_PERFECT_INLINED} className="lg:text-lg 2xl:text-2xl" color={form.isSubmitted ? "green" : "orange"}>
                    {form.isSubmitted ? <>
                      <AssignmentTurnedInIcon color="inherit" fontSize="inherit" />
                      <Typography marginLeft={1} className="lg:text-sm 2xl:text-md">Submitted</Typography>
                    </> : <><PendingActionsIcon color="inherit" fontSize="inherit" />
                      <Typography marginLeft={1} className="lg:text-sm 2xl:text-md">Not Submitted Yet</Typography>
                    </>}
                  </Box>
                </td>
                <td className={TD_LAST}>
                  <Link to="/enroll-form/registration-form"
                    state={{
                      formId: form.id,
                      previewMode: form.isSubmitted,
                      firstName: userInfo.firstName,
                      lastName: userInfo.lastName,
                      middleName: userInfo.middleName,
                      email: userInfo.email,
                      birthDate: userInfo.birthDate && userInfo.birthDate.split('T')[0],
                      traineeId: userInfo.id,
                      usi: userInfo.usi,
                    }}>
                    <Button variant="outlined" sx={{ marginRight: 2 }}>
                      {form.isSubmitted ? "View Form" : "View & Edit Form"}
                    </Button>
                  </Link>

                  <Button
                    variant="outlined"
                    color="error"
                    disabled={form.isSubmitted}
                    onClick={() => handleDeleteModal(form.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
              <Box marginY={1} />
            </>
          ))
          : <tr><td className="text-center py-4" colSpan={6}>Not enrol form found in this trainee</td></tr>}
      />

      <VmModal
        openModal={showModal}
        title={"Submit Enrolment Form"}
        onClose={() => setShowModal(false)}
        showButton={false}
      >
        <Typography sx={{ marginTop: -1, marginBottom: 2 }}>Create an new enrol form now?</Typography>
        <Box sx={ITEM_PERFECT_INLINED} className="justify-end">
          <Button
            variant="outlined"
            onClick={() => setShowModal(false)}
            color="error"
          >
            Not Now
          </Button>
          <Button
            variant="outlined"
            onClick={() => createNewEnrolForm()}
            sx={{ marginLeft: 2 }}
            color="success"
          >
            Create Now
          </Button>
        </Box>
      </VmModal>

      <VmModal
        openModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t('DELETE_A') + t('CONFIRMATION')}
        buttonLabel={t('CONFIRM_DELETE')}
        buttonLabelWithoutConfirm={t('DELETE_NOW')}
        buttonLoading={enrollmentStore.loading}
        onClickConfirmedButton={() => enrollmentStore.deleteEnrollForm(formId).then(() => {
          rootStore.notify('Enrolment Form' + t('DELETED_B'), 'success');
          enrollmentStore.getFormList();
          setFormId(0);
          setShowDeleteModal(false);
        }).catch(() => rootStore.notify(t('DELETE_FAILED'), 'error'))}
      >
        <Typography>{t('DELETE_CHECK')}: Enrolment Form?</Typography>
      </VmModal>
    </ContentLayout>
  )
});

export default EnrollFormIndexPage;
